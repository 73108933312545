function toggleDropdown() {
  // ON / OFF BUTTON
  let btn = document.getElementById("nav-icon1");
  if (btn != null) {
    btn.addEventListener("click", () => {
      document.getElementById("dropdown-menu").classList.toggle("hidden");
      btn.classList.toggle("open");
    });
  }
}

document.addEventListener("turbolinks:load", toggleDropdown);

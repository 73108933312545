import $ from 'jquery';
global.$ = jQuery;



function toggleTable() {
  setupTable();
  $('#show_table').click(function () {
    $("#ems_table_container").show();
    $(window).scrollTop("0px");
    $(document.body).css("overflow", "hidden");
    $('#meter-table').DataTable().draw();
  })
  $('#close_table').click(function () {
    $("#ems_table_container").hide();
    $(document.body).css("overflow", "initial");
  })

  window.onclick = function (event) {
    if (event.target == document.getElementById("ems_table_container")) {
      $(document.body).css("overflow", "initial");
      $("#ems_table_container").hide();
    }
  }
}

function setupTable() {
  var table = $('#meter-table');
  let dataT = table.DataTable({
    paginate: false,
    scrollY: "600px",
    scrollX: true,
    scrollCollapse: true,
    languange: {
      search: "Zoeken:",
      zeroRecords: "Geen resultaten gevonden",
    }
  });
}

document.addEventListener('turbolinks:load', toggleTable);


function clearTable() {
  $('#meter-table').DataTable().destroy();
}

document.addEventListener("turbolinks:before-cache", clearTable)

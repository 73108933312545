
function styleAccordion() {
  let headings = Array.from(document.getElementsByClassName("panel-heading"))

  headings.forEach(clickedHeading => {
    clickedHeading.addEventListener('click', () => {
      setTimeout(() => {
        headings.forEach(element => {
          element.style.backgroundColor = "#ffffff";
          let content = element.nextElementSibling;
          if (element  == clickedHeading) {
            content.classList.toggle("show");
          }
          content.classList.remove("show");
        });
        clickedHeading.style.background = "#e2e2e2"
      })
    }, 500);
    });
}

function flipArrows() {
  let btns = Array.from(document.getElementsByClassName('sub-accordion-btn'))
  btns.forEach(btn => {
    btn.addEventListener('click', () => {
      let arrow = btn.lastElementChild;
      arrow.classList.toggle('v-flip')
    })
  });
}

document.addEventListener('turbolinks:load', styleAccordion);
document.addEventListener('turbolinks:load', flipArrows);
